.shopping-cart {
	width: 100%;

	.item {
		position: relative;
		display: table;
		width: 100%;
		padding: 0 45px 30px 0;
		border-bottom: 2px solid #ededed;
		margin-bottom: 30px;

		.item-thumb,
		.item-details {
			display: table-cell;
			vertical-align: top;
		}

		.item-thumb {
			width: 165px;
			padding-right: 25px;
		}

		.item-thumb > img {
			width: 100%;
		}

		.item-remove {
			display: block;
			position: absolute;
			width: 36px;
			height: 36px;
			top: 0;
			right: 0;
			font-size: 14px;
			color: #606060;
			text-align: center;
			text-decoration: none;
		}

		.item-remove:hover {
			color: #ef0568;
		}
		.count-input {
			position: relative;
			width: 100px;
			margin: 10px 0;

			input {
				width: 100%;
				height: 36px;
				border: 2px solid #ededed;
				border-radius: 4px;
				background: none;
				text-align: center;
				-webkit-appearance: none;
				-moz-appearance: none;
				-o-appearance: none;
				appearance: none;
			}

			input:focus {
				outline: none;
			}

			.incr-btn {
				display: block;
				position: absolute;
				width: 36px;
				height: 36px;
				font-size: 18px;
				color: #606060;
				font-weight: 300;
				text-align: center;
				line-height: 33px;
				text-decoration: none;
				top: 0;
				right: 0;
			}

			.incr-btn:first-child {
				right: auto;
				left: 0;
			}

		}
		@media screen and (max-width: 480px) {
			padding-bottom: 20px;
			.item-thumb,
			.item-details {
				display: block;
			}
			.item-thumb {
				margin-bottom: 20px;
			}
		}
	}
}

.hidden {
	display: none;
}

// Invoice output

.full {
	width: 100%;
}
.text-right {
	text-align: right;
}
.valign-top {
	vertical-align: top;
}
.owl-carousel {
	.owl-item {
		.easyzoom-flyout {
			img {
				width: auto;
			}
		}
	}
}
// Hide vue cart on load
[v-cloak] {
	display: none !important;
}